import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertType, MatrixState } from 'data';
import { MatrixClient } from 'matrix-js-sdk'; // Replace with actual types if needed
import { RoomData } from '../../pages/Chat/types';

const initialState: MatrixState = {
  client: null,
  loading: true,
  showInvitations: false,
  showRooms: false,
  selectedRoom: undefined,
  publicRooms: [],
  showPersons: false,
  searchQuery: '',
  rooms: [],
  invitations: [],
  persons: [],
  error: null,
  alert: {
    open: false,
    severity: 'success',
    message: ''
  }
};

const matrixSlice = createSlice({
  name: 'matrix',
  initialState,
  reducers: {
    setClient(state, action: PayloadAction<MatrixClient>) {
      state.client = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setRooms(state, action: PayloadAction<RoomData[]>) {
      const newRooms = action.payload;

      // Create a Set to track unique room IDs
      const existingRoomIds = new Set(state.rooms.map((room) => room.roomId));

      // Filter new rooms to include only unique ones
      const uniqueNewRooms = newRooms.filter((room) => {
        if (!existingRoomIds.has(room.roomId)) {
          existingRoomIds.add(room.roomId); // Add roomId to the set
          return true; // Keep this room
        }
        return false; // Discard this room
      });

      // Update state with unique rooms
      state.rooms = [...state.rooms, ...uniqueNewRooms];
    },
    // Mark a room as deleted by roomId
    deleteRoom(state, action: PayloadAction<string>) {
      const roomIdToDelete = action.payload;
      console.log('roomIdToDelete', roomIdToDelete);

      // Mark the room as deleted by setting isDeleted to true
      const roomIndex = state.rooms.findIndex((room) => room.roomId === roomIdToDelete);

      if (roomIndex !== -1) {
        state.rooms[roomIndex].isDeleted = true;
      }

      // Mark the person as deleted by setting isDeleted to true
      const personIndex = state.persons.findIndex((person) => person.roomId === roomIdToDelete);

      if (personIndex !== -1) {
        state.persons[personIndex].isDeleted = true;
      }

      // Mark the ivites as deleted by setting isDeleted to true
      const invitationIndex = state.invitations.findIndex(
        (invite) => invite.roomId === roomIdToDelete
      );

      if (invitationIndex !== -1) {
        state.invitations[invitationIndex].isDeleted = true;
      }
    },
    // Mark a Person as invited by roomId
    acceptPersonInvite(state, action: PayloadAction<RoomData>) {
      const roomToInvited = action.payload;

      // Add the room to the persons array (if it's not already added)
      const personExists = state.persons.some((person) => person.roomId === roomToInvited.roomId);
      if (!personExists) {
        const room = { ...roomToInvited, isInviteAccepted: true };
        state.persons = [
          ...state.persons.filter((person) => person.roomId !== room.roomId), // Remove duplicates
          room as RoomData // Add the new room
        ];
      }

      // Mark the invite as accepted by setting isInviteAccepted to true
      const invitationIndex = state.invitations.findIndex(
        (invite) => invite.roomId === roomToInvited.roomId
      );

      if (invitationIndex !== -1) {
        // Update the invitation's `isInviteAccepted` to true
        state.invitations[invitationIndex] = {
          ...state.invitations[invitationIndex],
          isInviteAccepted: true
        };
      }
      state.selectedRoom = roomToInvited;
    },

    // Mark a Rooms as invited by roomId
    acceptRoomInvite(state, action: PayloadAction<RoomData>) {
      const roomToInvited = action.payload;

      // Add the roomToInvited to state.rooms if it doesn't exist already
      const roomExists = state.rooms.some((room) => room.roomId === roomToInvited.roomId);

      if (!roomExists) {
        state.rooms.push(roomToInvited); // Add the invited room to rooms
      }

      // Mark the invite as accepted by setting isInviteAccepted to true
      const invitationIndex = state.invitations.findIndex(
        (invite) => invite.roomId === roomToInvited.roomId
      );

      if (invitationIndex !== -1) {
        state.invitations[invitationIndex].isInviteAccepted = true;
      }
    },
    //add invitation roomId
    addInvitation(state, action: PayloadAction<RoomData>) {
      const roomToInvited = action.payload;
      state.invitations.push(roomToInvited);
    },
    setPublicRooms(state, action: PayloadAction<RoomData[] | undefined>) {
      const newPublicRooms = action.payload || [];

      // Create a Set to track unique room IDs
      const existingPublicRoomIds = new Set(state?.publicRooms?.map((room) => room.roomId));

      // Filter new public rooms to include only unique ones
      const uniqueNewPublicRooms = newPublicRooms.filter((room) => {
        if (!existingPublicRoomIds.has(room.roomId)) {
          existingPublicRoomIds.add(room.roomId); // Add roomId to the set
          return true; // Keep this room
        }
        return false; // Discard this room
      });

      // Update state with unique public rooms
      state.publicRooms = [...state.publicRooms, ...uniqueNewPublicRooms];
    },
    setInvitations(state, action: PayloadAction<RoomData[]>) {
      const newInvitations = action.payload;

      // Create a Set to keep track of unique room IDs
      const existingRoomIds = new Set(state.invitations.map((invite) => invite.roomId));

      // Filter new invitations to only include unique ones
      const uniqueNewInvitations = newInvitations.filter((invite) => {
        if (!existingRoomIds.has(invite.roomId)) {
          existingRoomIds.add(invite.roomId); // Add the roomId to the set
          return true; // Keep this invitation
        }
        return false; // Discard this invitation
      });

      // Update state with the unique invitations
      state.invitations = [...state.invitations, ...uniqueNewInvitations];
    },
    setPersons(state, action: PayloadAction<RoomData[]>) {
      // Create a Set based on roomId to ensure uniqueness
      const roomIds = new Set(state.persons.map((person) => person.roomId));

      // Filter out any new persons that already exist in the state
      const uniquePersons = action.payload.filter((newPerson) => {
        if (!roomIds.has(newPerson.roomId)) {
          roomIds.add(newPerson.roomId); // Add to the Set to keep track of added roomIds
          return true;
        }
        return false;
      });

      // Update the state with the unique persons
      state.persons = [...state.persons, ...uniquePersons];
    },
    setSelectedRoom(state, action: PayloadAction<RoomData | undefined>) {
      state.selectedRoom = action.payload;
    },
    setShowInvitations(state, action: PayloadAction<boolean>) {
      state.showInvitations = action.payload;
    },
    setShowRooms(state, action: PayloadAction<boolean>) {
      state.showRooms = action.payload;
    },
    setShowPersons(state, action: PayloadAction<boolean>) {
      state.showPersons = action.payload;
    },
    setSearchQuery(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setAlert(state, action: PayloadAction<AlertType>) {
      state.alert = action.payload;
    }
  }
});

export const {
  setClient,
  setLoading,
  setPersons,
  setPublicRooms,
  setAlert,
  setRooms,
  deleteRoom,
  acceptPersonInvite,
  acceptRoomInvite,
  setInvitations,
  addInvitation,
  setSelectedRoom,
  setShowInvitations,
  setShowRooms,
  setShowPersons,
  setSearchQuery,
  setError
} = matrixSlice.actions;

export default matrixSlice.reducer;
