export const ERRORS = {
  NETWORK_ERROR: {
    CODE: 503,
    MSG: 'Unable to connect. Please check your internet connection.'
  },
  UNAUTHORIZED: {
    CODE: 401,
    MSG: 'You are not authorized to perform this action.'
  },
  NOT_FOUND: {
    CODE: 404,
    MSG: 'The requested resource could not be found.'
  },
  INVALID_INPUT: {
    CODE: 400,
    MSG: 'Some of the provided inputs are invalid.'
  },
  SERVER_ERROR: {
    CODE: 500,
    MSG: 'An unexpected error occurred. Please try again later.'
  },
  FETCH_FAILED: {
    CODE: 502,
    MSG: 'Failed to fetch data. Please try again.'
  },
  TIMEOUT: {
    CODE: 408,
    MSG: 'The request timed out. Please try again later.'
  },
  MISSING_FIELDS: {
    CODE: 422,
    MSG: 'Required fields are missing. Please fill them in and try again.'
  },
  DUPLICATE_ENTRY: {
    CODE: 409,
    MSG: 'This entry already exists.'
  },
  OPERATION_FAILED: {
    CODE: 400,
    MSG: 'The operation could not be completed.'
  },
  UNKNOWN_ERROR: {
    CODE: 520,
    MSG: 'An unknown error occurred. Please contact support.'
  }
};
