import { createSelector } from 'reselect';
import { DoctorVerificationState, RootState } from 'data'; // Update with your actual import paths

// Base selector for doctor-verification state
const doctorVerificationSelector = (state: RootState) => state.doctorVerification;

/**
 * Helper function to create memoized selectors for doctor verification fields
 * @param field - The field to select
 */
const createDoctorVerificationSelector = <T extends keyof DoctorVerificationState>(field: T) =>
  createSelector(
    [doctorVerificationSelector],
    (doctorVerificationState) => doctorVerificationState[field]
  );

// Export specific selectors
export const selectDoctorVerificationList = createDoctorVerificationSelector('list');
export const selectDoctorVerificationStats = createDoctorVerificationSelector('verificationStats');
export const selectDoctorVerificationLoading = createDoctorVerificationSelector('loading');
export const selectDoctorVerificationError = createDoctorVerificationSelector('error');
