import { createSlice } from '@reduxjs/toolkit';
import {
  getDoctorVerificationStats,
  getUserDetailsDoctorVerification
} from '../../services/doctor-verification';
import { DoctorVerificationState } from 'data/types';
import { createApiThunk } from 'data/helper';

const initialState: DoctorVerificationState = {
  list: {
    practiseBasicDetails: []
  },
  verificationStats: {
    pendingVerification: {
      aggregate: {
        count: 0
      }
    },
    verifiedPractices: {
      aggregate: {
        count: 0
      }
    },
    rejectedVerification: {
      aggregate: {
        count: 0
      }
    },
    reVerification: {
      aggregate: {
        count: 0
      }
    }
  },
  loading: false,
  error: null
};

// Create an async thunk for fetching doctor details
export const fetchDoctorVerificationList = createApiThunk(
  'doctorVerification/fetchDoctorVerificationList',
  getUserDetailsDoctorVerification
);

export const fetchDoctorVerificationStats = createApiThunk(
  'doctorVerification/fetchDoctorVerificationStats',
  getDoctorVerificationStats
);

const doctorVerificationSlice = createSlice({
  name: 'doctorVerification',
  initialState,
  reducers: {
    setVerificationLoading: (state, action) => {
      state.loading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDoctorVerificationList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDoctorVerificationList.fulfilled, (state, action) => {
        state.list = action.payload;
        state.loading = false;
      })
      .addCase(fetchDoctorVerificationList.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      })

      .addCase(fetchDoctorVerificationStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDoctorVerificationStats.fulfilled, (state, action) => {
        state.verificationStats = action.payload;
        state.loading = false;
      })
      .addCase(fetchDoctorVerificationStats.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});
export const { setVerificationLoading } = doctorVerificationSlice.actions;

export default doctorVerificationSlice.reducer;
