import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Grid, Box, CircularProgress } from '@mui/material';
import './assets/fonts/fonts.css';
import './App.css';
import './components/loading/index.css';
import Navbar from 'components/header';
import routeData from './../src/config/routes.json';
import PageNotFound from './pages/PageNotFound';

// Type for dynamically imported components
type PageComponentType = React.ComponentType;

const loadPage = (pageName: string): PageComponentType => {
  switch (pageName) {
    case 'dashboard':
      return React.lazy(() => import('./pages/Dashboard'));
    case 'doctorManagement':
      return React.lazy(() => import('./pages/DoctorManagement'));
    case 'clinicManagement':
      return React.lazy(() => import('./pages/ClinicManagement'));
    case 'patientManagement':
      return React.lazy(() => import('./pages/PatientManagement'));
    case 'demographics':
      return React.lazy(() => import('./pages/Demographics'));
    case 'appPerformance':
      return React.lazy(() => import('./pages/AppPerformance'));
    case 'specialisationManager':
      return React.lazy(() => import('./pages/SpecialisationManager'));
    case 'chat':
      return React.lazy(() => import('./pages/Chat'));
    case 'doctorsVerification':
      return React.lazy(() => import('./pages/DoctorsVerification'));   
    case 'roleManagement':
      return React.lazy(() => import('./pages/RoleManagement'));
      case 'doctorDetails':
      return React.lazy(() => import('./pages/DoctorDetails'));
    default:
      return PageNotFound; // Fallback component
  }
};

function App() {
  return (
    <BrowserRouter>
      <Box className="app-container">
        <Box component="header" className="header">
          <Navbar />
        </Box>
        <Grid container className="main-content">
          <Grid item xs={12} className="content-area">
            <Grid item xs={12} className="routes">
              <React.Suspense fallback={<CircularProgress />}>
                <Routes>
                  {routeData.map((route) => {
                    const PageComponent = loadPage(route.name);

                    
                    if (Array.isArray(route.path)) {
                      return route.path.map((path) => (
                        <Route key={path} path={path} element={<PageComponent />} />
                      ));
                    } else {
                      return (
                        <Route key={route.path} path={route.path} element={<PageComponent />} />
                      );
                    }
                  })}

                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </React.Suspense>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </BrowserRouter>
  );
}

export default App;
