import { handleResponse, axiosInstance, API } from './helper';

interface RoleDetails {
  practiseId: string;
  practiseUserId: string;
  role: string;
}
const getRoleManagementStats = () => handleResponse(axiosInstance.get(API.getRoleManagementStats));

const getUserDetailsRoleManagement = () =>
  handleResponse(axiosInstance.get(API.getUserDetailsRoleManagement));

const insertRoleDetails = (payload: RoleDetails) =>
  handleResponse(axiosInstance.put(API.insertRoleDetails, payload));

export { getRoleManagementStats, getUserDetailsRoleManagement, insertRoleDetails };
