import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconMenu2 } from '@tabler/icons-react';
import profilePic from '../../assets/images/profilePic.png';
import './index.css';
import { IconButton, Drawer, Box } from '@mui/material';
import SideNavbar from 'components/sidebar';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <div className="navbar">
        <div className="menu-icon">
          <IconButton className="icon-button-menu" onClick={handleDrawerOpen}>
            <IconMenu2 />
          </IconButton>
        </div>
        <div className="admin-info">
          <div className="admin-role">{t('admin')}</div>
          <div className="admin-name">Super Admin</div>
        </div>
        <div className="profile-image">
          <img src={profilePic} alt="Admin" />
        </div>
      </div>

      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        sx={{
          '& .MuiPaper-root': {
            width: 240
          }
        }}>
        <Box role="presentation" onClick={handleDrawerClose} onKeyDown={handleDrawerClose}>
          <SideNavbar />
        </Box>
      </Drawer>
    </>
  );
};

export default Header;
