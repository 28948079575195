export const API_ROUTES = {
  getAdditionalQualifications: 'get-additional-qualifications',
  getService: 'get-services',
  insertFocusQualifications: 'insert-focus-qualifications',
  updateSpeciality: 'update-speciality',
  insertQualifications: 'insert-qualifications',
  getSpecialistQualificationList: 'get-specialist-qualification-list',
  getDoctorManagementDetails: 'get-doctor-management-details',
  deleteQualifications: 'delete-qualifications',
  insertAdditionalQualifications: 'insert-additional-qualifications',
  insertTreatmentService: 'insert-billable-services',
  getTreatmentQualifications: 'get-treatment-qualifications',
  deleteBillableServices: 'delete-billable-services',
  updateServiceQualifications: 'update-service-qualifications',
  getUserDetailsDoctorVerification: 'get-user-details-doctor-verification',
  getDoctorVerificationStats: 'get-doctor-verification-stats',
  getRoleManagementStats: 'get-role-management-stats',
  insertRoleDetails: 'insert-practise-admin-user-organisation',
  getUserDetailsRoleManagement: 'detail',
  updateProfessionalDetailsVerificationStatus: 'update-professional-details-verification-status',
  updateIdDocumentsVerificationStatus: 'update-id-documents-verification-status',
  updateLivePictureVerificationStatus: 'update-live-picture-verification-status',
  updatePractiseOverAllVerificationStatus: 'update-practise-over-all-verification-status',
  askForUpdateIdDocumentsVerificationStatus: 'ask-for-update-id-documents-verification-status',
  askForUpdateLivePictureVerificationStatus: 'ask-for-update-live-picture-verification-status',
  askForUpdateProfessionalDetailsVerificationStatus:
    'ask-for-update-professional-details-verification-status'
};
