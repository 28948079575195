import axios from 'axios';

export const kcTokenEndPoint = '/realms/test-mydoc/protocol/openid-connect/token';
export const REALMS_USERS_URL = '/admin/realms/test-mydoc/users';

export const getRealmToken = async () => {
  try {
    const formData = {
      grant_type: 'client_credentials',
      client_id: 'login',
      client_secret: 'Jxf2VF7LmC7fgUzLpLW9tUM10eTgrgEJ'
    };
    const response = await axios.post(kcTokenEndPoint, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    const data = await response.data;
    // console.log(data.access_token, 'data.access_token');
    return data.access_token;
  } catch (error) {
    console.log('🚀 ~ file: auth.ts:216 ~ getRealmToken ~ error:', error);
    return null;
  }
};

export const getRealmUserInfo = async (id: string) => {
  try {
    const token = await getRealmToken();

    const response = await axios.get(`${REALMS_USERS_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    console.log('response', response);
    const data = await response.data;
    return data || {};
  } catch (error) {
    console.log('🚀 ~ file: auth.ts:232 ~ getRealmUserInfo ~ error:', error);
    return null;
  }
};

//   https://iam.spicter.io/admin/realms/mydoc/users
//   https://iam.spicter.io/realms/mydoc/protocol/openid-connect/token
