import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  getSpecialisationData,
  getAdditionalQualificationData,
  getTreatmentServices,
  getServiceQualifications
} from '../../services';
import { ERRORS } from '../../constants/errors';

// Create async thunk for fetching specialisation data
export const fetchSpecialisations = createAsyncThunk(
  'specialistQualifications/fetchSpecialisations',
  async (_, { rejectWithValue }) => {
    try {
      const result = await getSpecialisationData();
      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Handle Axios-specific errors
        if (!error.response) {
          return rejectWithValue(ERRORS.NETWORK_ERROR.MSG);
        }

        switch (error.response.status) {
          case 404:
            return rejectWithValue(ERRORS.NOT_FOUND.MSG);
          case 500:
          case 503:
            return rejectWithValue(ERRORS.SERVER_ERROR.MSG);
          default:
            return rejectWithValue(error.response.data?.message || error.message);
        }
      } else {
        // Handle non-Axios errors
        console.error('Unexpected error:', error);
        return rejectWithValue(ERRORS.UNKNOWN_ERROR.MSG);
      }
    }
  }
);

// Create async thunk for fetching additional qualifications data
export const fetchAdditionalQualifications = createAsyncThunk(
  'specialistQualifications/fetchAdditionalQualifications',
  async (_, { rejectWithValue }) => {
    try {
      const result = await getAdditionalQualificationData();
      console.log(result, 'add data');
      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Handle Axios-specific errors
        if (!error.response) {
          return rejectWithValue(ERRORS.NETWORK_ERROR.MSG);
        }

        switch (error.response.status) {
          case 404:
            return rejectWithValue(ERRORS.NOT_FOUND.MSG);
          case 500:
          case 503:
            return rejectWithValue(ERRORS.SERVER_ERROR.MSG);
          default:
            return rejectWithValue(error.response.data?.message || error.message);
        }
      } else {
        // Handle non-Axios errors
        console.error('Unexpected error:', error);
        return rejectWithValue(ERRORS.UNKNOWN_ERROR.MSG);
      }
    }
  }
);
// Create async thunk for fetching treatments
export const fetchTreatments = createAsyncThunk(
  'specialistQualifications/fetchTreatments',
  async (_, { rejectWithValue }) => {
    try {
      const result = await getTreatmentServices();
      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Handle Axios-specific errors
        if (!error.response) {
          return rejectWithValue(ERRORS.NETWORK_ERROR.MSG);
        }

        switch (error.response.status) {
          case 404:
            return rejectWithValue(ERRORS.NOT_FOUND.MSG);
          case 500:
          case 503:
            return rejectWithValue(ERRORS.SERVER_ERROR.MSG);
          default:
            return rejectWithValue(error.response.data?.message || error.message);
        }
      } else {
        // Handle non-Axios errors
        console.error('Unexpected error:', error);
        return rejectWithValue(ERRORS.UNKNOWN_ERROR.MSG);
      }
    }
  }
);

// Create async thunk for fetching service qualifications
export const fetchServiceQualifications = createAsyncThunk(
  'specialistQualifications/fetchServiceQualifications',
  async (_, { rejectWithValue }) => {
    try {
      const result = await getServiceQualifications();
      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Handle Axios-specific errors
        if (!error.response) {
          return rejectWithValue(ERRORS.NETWORK_ERROR.MSG);
        }

        switch (error.response.status) {
          case 404:
            return rejectWithValue(ERRORS.NOT_FOUND.MSG);
          case 500:
          case 503:
            return rejectWithValue(ERRORS.SERVER_ERROR.MSG);
          default:
            return rejectWithValue(error.response.data?.message || error.message);
        }
      } else {
        // Handle non-Axios errors
        console.error('Unexpected error:', error);
        return rejectWithValue(ERRORS.UNKNOWN_ERROR.MSG);
      }
    }
  }
);
