import { API, axiosInstance, handleResponse } from './helper';

interface updateVerificationStatusPayload {
  status: string;
  practiseAdminId: string;
}
interface updateIdStatusPayload {
  status: string;
  practiseAdminId: string;
}
interface updateLivePicStatusPayload {
  status: string;
  practiseAdminId: string;
}
interface updateOverAllStatusPayload {
  verificationStatus: string;
  practiseAdminId: string;
}



const getUserDetailsDoctorVerification = () =>
  handleResponse(axiosInstance.get(API.getUserDetailsDoctorVerification));

const getDoctorVerificationStats = () =>
  handleResponse(axiosInstance.get(API.getDoctorVerificationStats));

const updateProfessionalDetailsVerificationStatus = (
  update_verification_status_payload: updateVerificationStatusPayload
) =>
  handleResponse(
    axiosInstance.put(
      API.updateProfessionalDetailsVerificationStatus,
      update_verification_status_payload
    )
  );

const updateIdDocumentsVerificationStatus = (update_id_status_payload: updateIdStatusPayload) =>
  handleResponse(
    axiosInstance.put(API.updateIdDocumentsVerificationStatus, update_id_status_payload)
  );

const updateLivePictureVerificationStatus = (
  update_live_pic_status_payload: updateLivePicStatusPayload
) =>
  handleResponse(
    axiosInstance.put(API.updateLivePictureVerificationStatus, update_live_pic_status_payload)
  );


const askForUpdateProfessionalDetailsVerificationStatus = (
  ask_for_update_verification_status_payload: updateVerificationStatusPayload
) =>
  handleResponse(
    axiosInstance.put(
      API.askForUpdateProfessionalDetailsVerificationStatus,
      ask_for_update_verification_status_payload
    )
  );

const askForUpdateIdDocumentsVerificationStatus = (update_id_status_payload: updateIdStatusPayload) =>
  handleResponse(
    axiosInstance.put(API.askForUpdateIdDocumentsVerificationStatus, update_id_status_payload)
  );

const askForUpdateLivePictureVerificationStatus = (
  update_live_pic_status_payload: updateLivePicStatusPayload
) =>
  handleResponse(
    axiosInstance.put(API.updateLivePictureVerificationStatus, update_live_pic_status_payload)
  );

const updatePractiseOverAllVerificationStatus = (
  update_over_all_status_payload: updateOverAllStatusPayload
) =>
  handleResponse(
    axiosInstance.put(API.updatePractiseOverAllVerificationStatus, update_over_all_status_payload)
  );

export {
  getUserDetailsDoctorVerification,
  getDoctorVerificationStats,
  updateProfessionalDetailsVerificationStatus,
  updateIdDocumentsVerificationStatus,
  updateLivePictureVerificationStatus,
  updatePractiseOverAllVerificationStatus,
  askForUpdateProfessionalDetailsVerificationStatus,
  askForUpdateIdDocumentsVerificationStatus,
  askForUpdateLivePictureVerificationStatus
};
