// dashboardSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getDashboardData } from '../../services';
import axios from 'axios';
import { ERRORS } from '../../constants';

// Define the initial state
const initialState = {
  list: [],
  loading: false,
  error: null as string | null
};

// Create an async thunk for fetching data
export const fetchDashboardData = createAsyncThunk(
  'dashboard/fetchData',
  async (_, { rejectWithValue }) => {
    try {
      const result = await getDashboardData();
      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (!error.response) {
          // Network error
          return rejectWithValue(ERRORS.NETWORK_ERROR.MSG);
        }

        switch (error.response.status) {
          case 404:
            return rejectWithValue(ERRORS.NOT_FOUND.MSG);
          case 500:
          case 503:
            return rejectWithValue(ERRORS.SERVER_ERROR.MSG);
          default:
            return rejectWithValue(error.response.data?.message || error.message);
        }
      } else {
        // Handle non-Axios errors
        console.error('Unexpected error:', error);
        return rejectWithValue(ERRORS.UNKNOWN_ERROR.MSG);
      }
    }
  }
);

// Create the slice
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  }
});

export default dashboardSlice.reducer;
